import React from "react"
import "./404.scss"
import { navigate } from "gatsby"

// illustrations
import earth from "../../assets/earth.svg"

const NotFound: React.FC = () => {
  return (
    <div className="notfound">
      <div className="notfound__container">
        <span>4</span>
        <img
          className="notfound__earth"
          src={earth}
          alt="Illustration of Earth"
        />
        <span>4</span>
      </div>
      <p>
        looks like there's nothing here.{" "}
        <span onClick={() => navigate("/")}>go back</span>
      </p>
    </div>
  )
}

export default NotFound
